import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class FooterLinks extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="contact-social bg-footer-img">
        <div className="bg-overlay-alt"></div>
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className="align-items-center-alt">
                <ul className="list-inline social mt-4">
                  <li className="list-inline-item">
                    <a href="https://facebook.com/privkeyllc" className="social-icon">
                      <i className="mdi mdi-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                  <a href="https://twitter.com/privkey_io" className="social-icon">
                      <i className="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/privkeyllc/" className="social-icon">
                      <i className="mdi mdi-linkedin"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                  <a href="https://www.instagram.com/accounts/login/?next=/privkey_io/" className="social-icon">
                      <i className="mdi mdi-instagram"></i>
                    </a>
                  </li>
                </ul>
                <p className="copy-rights text-muted">
                    2025 © PrivKey LLC Clavis Privatus
                  </p>
              </Col>
              <Col lg={6} className="align-items-center-alt padding-desktop">
                <p className="contact-title">
                  <a href="tel:+19292600738"  className="mail-cellphone">
                  <i className="pe-7s-call"></i> &nbsp;+1 929 260 0738
                  </a>
                </p>
                <p className="contact-title">
                <a href="mailto:information@privkey.io" className="mail-cellphone"><i className="pe-7s-mail-open"></i>&nbsp; information@privkey.io</a>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
