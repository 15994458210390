import React, { Component } from "react";
import { Col, Row } from "reactstrap";

class TeamBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.teams.map((team, key) => (
          <Col key={key} lg={6} sm={6}>
            <div className="team-box text-center">
              <div className="team-wrapper">
                <div className="team-member">
                  <img
                    alt="team"
                    src={team.image}
                    className="img-fluid rounded"
                    style={{ height: "500px" }}
                  />
                </div>
              </div>
              <Row>
                <h4 className="team-name">{team.title}</h4>
              </Row>
              <Row className="margin-social-icon">
                <span className="text-uppercase team-designation">{team.desc}</span>
              </Row>
                <div className="outer-flex-div-team-box">
                  <div className="inner-flex-div-team-box">
                    {team.twitter && (
                      <a href={team.twitter} className="social-icon">
                        <i className="mdi mdi-twitter"></i>
                      </a>
                    )}
                    {team.linkedIn && (
                      <a href={team.linkedIn} className="social-icon">
                        <i className="mdi mdi-linkedin"></i>
                      </a>
                    )}
                  </div>
                </div>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default TeamBox;