import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TeamBox from "./TeamBox";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          image: "assets/images/william_profile.png",
          title: "William K. Santiago",
          desc: "FOUNDER & CEO",
          linkedIn: "https://linkedin.com/in/wksantiago",
          twitter: "https://twitter.com/williamsantiago",
        },
        {
          image: "assets/images/kyle_profile.png",
          title: "Kyle W. Santiago",
          desc: "CO-FOUNDER",
          linkedIn: "https://linkedin.com/in/kwsantiago",
        },
        // {
        //   image: "assets/images/developer.png",
        //   title: "Vacant Position",
        //   desc: "DEVELOPER",
        //   linkedIn: "https://linkedin.com/in/developer",
        //   twitter: "",
        // },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-back section-title-white" id="team">
          <Container>
            {/* section title */}
            <SectionTitle
              title="MEET OUR TEAM"
              desc="We lead the industry through continuous R&D, backed by a team of top cybersecurity experts and advisors. Our services stay cutting-edge, compliant, and aligned with the latest advancements in cybersecurity, blockchain, and private key management. Your security is our priority—always."
            />
            <div className="margin-above-sub"></div>
            <Row>
              {/* team box */}
              <TeamBox teams={this.state.teams} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;