import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";

class WebsiteDesc extends Component {
  render() {
    const desc = "With over 30 years of experience in networking, cybersecurity, and internet fields, PrivKey is a leading provider of cybersecurity and digital asset solutions. We specialize in blockchain technology, delivering expert consulting, smart contract development, and multisig wallet management to help businesses harness its potential. Our security solutions for cryptocurrency wallets—including multi-sig implementation, hardware wallet setup, management, and regular audits—keep your digital assets safe.<br></br>Our cybersecurity services, such as threat intelligence, penetration testing, incident response, security consulting, and training, protect your networks, systems, and assets from evolving threats. We also offer compliance support, guiding you through AML/KYC regulations and other legal standards, while our IT team designs tailored, high-quality systems, networks, virtual infrastructure, and cloud solutions to fit your needs. At PrivKey, we craft customized solutions that meet your specific goals and ensure compliance with all relevant laws and regulations.";

    return (
      <React.Fragment>
        <section className="section " id="who-we-are">
          <Container>
            <SectionTitle
              title="WHO WE ARE"
              desc={<p style={{color: 'black'}} dangerouslySetInnerHTML={{__html: desc}} />}
            />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDesc;