import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-wallet",
          title: "Wallet Security & Key Management",
          desc:
            "Advanced wallet security and private key management solutions, including Multi-Party Computation (MPC) for enhanced asset protection. Our Multisig concierge service empowers businesses to securely manage their private and public keys with step-by-step guidance and best-practice operational security consulting. We ensure your digital assets are safeguarded against unauthorized access and cyber threats.",
        },
        {
          icon: "pe-7s-lock",
          title: "Cybersecurity Services",
          desc:
            "We deliver comprehensive cybersecurity services designed to protect your enterprise’s networks, systems, and digital assets, with a specialized focus on private key management. Our services include threat intelligence, penetration testing, incident response, security consulting, and training. Our experts provide real-time monitoring, simulate real-world hacking scenarios, and offer strategic advice to mitigate risks, ensuring the security of your private keys and digital assets.",
        },
        {
          icon: "pe-7s-safe",
          title: "Digital Assets, DLT & Blockchain Consulting",
          desc:
            "Specialized consulting and development services for enterprises aiming to harness the power of Distributed Ledger Technology (DLT) and blockchain, with a focus on secure private key management. Our expertise includes blockchain strategy, design, and implementation, ensuring robust security for digital assets. We also provide guidance on Central Bank Digital Currencies (CBDCs) and Security Token Offerings (STOs), emphasizing secure key management practices.",
        }
      ],
      services2: [
        {
          icon: "pe-7s-note2",
          title: "Auditing & Certification",
          desc:
            "We offer auditing and certification services to ensure your enterprise meets the highest standards of security, including compliance with the CryptoCurrency Security Standard (CCSS). Our focus on private key management ensures that your cryptocurrency assets are protected according to best practices. We provide guidance that complements existing information security standards, helping you secure digital assets like Bitcoin with confidence.",
        },
        {
          icon: "pe-7s-plugin",
          title: "Smart Contract Development",
          desc:
            "Our team of expert developers excels in creating and auditing smart contracts with a strong emphasis on security and private key management. We collaborate closely with clients to design solutions that meet industry standards and best practices, ensuring the integrity of digital assets. Our rigorous auditing process identifies and mitigates vulnerabilities, and we support a wide range of blockchain platforms, including Ethereum, RSK, Solana, and more.",
        },
        {
          icon: "pe-7s-tools",
          title: "Complete IT Solutions",
          desc:
            "We design, implement, and integrate high-quality IT systems, networks, and cloud solutions with a focus on secure private key management. Our services ensure compliance with AML/KYC regulations and include AI-powered threat detection and response to safeguard against cybersecurity risks. Additionally, we integrate AI into blockchain technologies to enhance efficiency and security, providing a robust framework for managing digital assets.",
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section bg-back section-title-white"} id="services" >
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Services"
              desc="PrivKey empowers businesses to thrive in the digital age with cutting-edge cybersecurity, blockchain consulting, and private key management solutions. From smart contract development to our unique multisig concierge support, we deliver secure, compliant, and customized blockchain implementations that safeguard your assets and unlock the full potential of distributed ledger technology. Partner with us to innovate confidently and stay ahead of the curve."
              />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
